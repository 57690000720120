import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { AuthState } from './interfaces'
import { refreshAccessToken } from './authAPI'
import CryptoJS from 'crypto-js'

const initialState: AuthState = {
    status: 'idle',
    refreshToken: '',
    accessToken: '',
    accessTokenExpiration: '',
    user: null,
    isTokenExpired: false,
}

// Async function for uploading the sequences.
export const refreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (_, { getState }) => {
        const state = getState() as RootState
        const response = await refreshAccessToken(state.auth?.refreshToken)
        return response.data
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetAuthData: (state) => {
            state.status = 'idle'
            state.user = null
            state.refreshToken = ''
            state.accessToken = ''
            state.accessTokenExpiration = ''
            state.isTokenExpired = false
        },
        setAuthData: (state, action) => {
            state.user = action.payload.user
            state.accessToken = action.payload.access_token
            state.refreshToken = action.payload.refresh_token
        },
        setUserData: (state, action) => {
            state.user = action.payload
        },
        updateAccessToken: (state, action) => {
            if (state.user) {
                state.accessToken = action.payload.accessToken
                state.accessTokenExpiration =
                    action.payload.accessTokenExpiration
                state.isTokenExpired = false
            }
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(refreshToken.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(refreshToken.rejected, (state) => {
                // Here we reset auth info. to force a redirect to auth/login.
                // @todo It would be nice to display a message to the user that their session has expired.
                state.isTokenExpired = true
                state.status = 'idle'
                state.refreshToken = ''
                state.accessToken = ''
                state.accessTokenExpiration = ''
                state.user = null
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                state.status = 'idle'
                if (state.user) {
                    state.accessToken = action.payload.access
                    state.accessTokenExpiration =
                        action.payload.access_token_expiration
                    state.isTokenExpired = false
                }
            })
    },
})

export const { resetAuthData, setAuthData, updateAccessToken, setUserData } =
    authSlice.actions

export const selectAuth = (state: RootState) => {
    const decryptedUser = state?.auth?.user;
    if (typeof decryptedUser === 'object' && decryptedUser?.encryptedData && decryptedUser?.hash) {

        try {
            // Decrypt the data
            const bytes = CryptoJS.AES.decrypt(
                decryptedUser.encryptedData,
                process.env.REACT_APP_AUTH_SECRET_KEY || 'energyrocks!'
            );
            const decryptedString = bytes.toString(CryptoJS.enc.Utf8);

            if (!decryptedString) {
                console.error('Failed to decrypt user data');
                return null;
            }

            // Parse the decrypted data
            const decryptedData = JSON.parse(decryptedString);

            // Verify integrity with hash
            const computedHash = CryptoJS.HmacSHA256(
                decryptedString,
                process.env.REACT_APP_AUTH_SECRET_KEY || 'energyrocks!'
            ).toString();

            if (computedHash !== decryptedUser.hash) {
                console.warn('Auth data tampering detected!');
                return null;
            }

            state.auth.user = decryptedData;
        } catch (error) {
            console.error('Error decrypting user data:', error);
            state.auth.user = null;
        }
    }


    return state.auth
}

export default authSlice.reducer
