import { lazy } from 'react'
import Documentation from '../pages/Documentation'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
//import Dashboard from "../pages/Dashboard";

// use lazy for better code splitting, a.k.a. load faster
const Project = lazy(() => import('../pages/ProjectDetails'))
const Projects = lazy(() => import('../pages/Projects'))
const Profile = lazy(() => import('../pages/Profile'))
const Settings = lazy(() => import('../pages/Settings'))
const Logout = lazy(() => import('../pages/Logout'))
// const Profile = lazy(() => import('../pages/Profile'));
// const Users = lazy(() => import('../pages/Users'));
// const Billing = lazy(() => import('../pages/Billing'));
// const Teams = lazy(() => import('../pages/Teams'));
// const TeamDetails = lazy(() => import('../pages/TeamDetails'));
// const TeamInvitation = lazy(() => import('../pages/TeamInvitation'));
const Dashboard = lazy(() => import('../pages/Dashboard'))
const WelcomePage = lazy(() => import('../pages/WelcomePage'))
// const Forms = lazy(() => import('../pages/Forms'));
// const Cards = lazy(() => import('../pages/Cards'));
// const Charts = lazy(() => import('../pages/Charts'));
// const Buttons = lazy(() => import('../pages/Buttons'));
// const Modals = lazy(() => import('../pages/Modals'));
// const Tables = lazy(() => import('../pages/Tables'));
const Page404 = lazy(() => import('../pages/404'))
const Blank = lazy(() => import('../pages/Blank'))
const TariffExample = lazy(() => import('../pages/TariffExample'))
const Users = lazy(() => import('../pages/Users'))

// Staff-only route wrapper component
const StaffRoute = ({ element }: { element: JSX.Element }) => {
    // Get user from Redux store instead of localStorage
    const user = useSelector((state: any) => state?.auth?.user)
    // If user is not staff, redirect to dashboard
    if (!user?.is_staff) {
        return <Navigate to="/app" replace />
    }
    
    // Otherwise, render the protected component
    return element
}

/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */
const routes = [
    {
        path: 'projects',
        element: <Projects />,
    },
    {
        path: 'projects/:projectId',
        element: <Project defaultTab="" />,
    },
    {
        path: 'projects/:projectId/overview',
        element: <Project defaultTab="overview" />,
    },
    {
        path: 'projects/:projectId/charging-hub',
        element: <Project defaultTab="charging-hub" />,
    },
    {
        path: 'projects/:projectId/financial-detail',
        element: <Project defaultTab="financial-detail" />,
    },
    {
        path: 'projects/:projectId/financial-detail/sensitivity',
        element: (
            <Project defaultTab="financial-detail" defaultForm="sensitivity" />
        ),
    },
    {
        path: 'projects/:projectId/financial-detail/timeseries',
        element: (
            <Project defaultTab="financial-detail" defaultForm="timeseries" />
        ),
    },
    {
        path: 'projects/:projectId/financial-detail/tariff',
        element: <Project defaultTab="financial-detail" defaultForm="tariff" />,
    },
    {
        path: 'projects/:projectId/financial-detail/charging-hub',
        element: (
            <Project defaultTab="financial-detail" defaultForm="charging-hub" />
        ),
    },
    {
        path: 'projects/:projectId/financial-detail/fleet',
        element: <Project defaultTab="financial-detail" defaultForm="fleet" />,
    },
    {
        path: 'projects/:projectId/financial-detail/energy-system',
        element: (
            <Project
                defaultTab="financial-detail"
                defaultForm="energy-system"
            />
        ),
    },
    {
        path: 'projects/:projectId/financial-detail/generic',
        element: (
            <Project defaultTab="financial-detail" defaultForm="generic" />
        ),
    },
    {
        path: 'projects/:projectId/optimization',
        element: <Project defaultTab="optimization" />,
    },
    {
        path: 'projects/:projectId/energy-system',
        element: <Project defaultTab="energy-system" />,
    },
    {
        path: 'projects/:projectId/ev-fleet',
        element: <Project defaultTab="ev-fleet" />,
    },
    // {
    //   path: '/profile',
    //   component: Profile,
    // },
    // {
    //   path: '/users',
    //   component: Users,
    // },
    // {
    //   path: '/billing',
    //   component: Billing,
    // },
    // {
    //   path: '/teams',
    //   component: Teams,
    // },
    // {
    //   path: '/teams/:teamId',
    //   component: TeamDetails,
    // },
    // {
    //   path: '/team-invitation/:teamId',
    //   component: TeamInvitation,
    // },
    {
        path: 'support',
        element: <WelcomePage />,
    },
    {
        path: 'settings',
        element: <Settings />,
    },
    {
        path: 'logout',
        element: <Logout />,
    },
    {
        path: 'welcome',
        element: <WelcomePage />,
    },
    {
        path: 'dashboard', // the url
        element: <Dashboard />, // view rendered
    },
    {
        path: 'profile',
        element: <Profile />,
    },
    {
        path: 'documentation',
        element: <Documentation />,
    },
    // {
    //   path: '/forms',
    //   component: Forms,
    // },
    // {
    //   path: '/cards',
    //   component: Cards,
    // },
    // {
    //   path: '/charts',
    //   component: Charts,
    // },
    // {
    //   path: '/buttons',
    //   component: Buttons,
    // },
    // {
    //   path: '/modals',
    //   component: Modals,
    // },
    // {
    //   path: '/tables',
    //   component: Tables,
    // },
    {
        path: '404',
        element: <Page404 />,
    },
    {
        path: 'blank',
        element: <Blank />,
    },
    {
        path: 'faq/tariff',
        element: <TariffExample />,
    },
    {
        path: 'users',
        element: <StaffRoute element={<Users />} />,
    },
]

export default routes
