import { createTransform } from 'redux-persist';
import CryptoJS from 'crypto-js';

// Use a fallback value if the environment variable is undefined
const SECRET_KEY = process.env.REACT_APP_AUTH_SECRET_KEY || 'energyrocks!';

// Add this interface at the top of the file
interface EncryptedState {
  encryptedData: string;
  hash: string;
}

// Create a transform to secure sensitive auth data
const SecureAuthTransform = createTransform(
    // Transform state on its way to being serialized and persisted
    (inboundState, key) => {
        if (key === 'user' && inboundState) {
            // Convert user data to string
            const userString = JSON.stringify(inboundState);
            
            // Encrypt the user data
            const encryptedData = CryptoJS.AES.encrypt(userString, SECRET_KEY).toString();
            
            // Create a hash for integrity verification
            const dataHash = CryptoJS.HmacSHA256(userString, SECRET_KEY).toString();
            
            // Return only the encrypted data and hash
            return {
                encryptedData,
                hash: dataHash
            };
        }
        return inboundState;
    },
    // Transform state being rehydrated
    (outboundState, key) => {
        if (key === 'user' && outboundState) {
            // Check if we have the expected structure with encrypted data
            if (typeof outboundState === 'object' && 
                'encryptedData' in outboundState && 
                'hash' in outboundState) {
                
                const secureState = outboundState as EncryptedState;
                
                try {
                    // Decrypt the data
                    const bytes = CryptoJS.AES.decrypt(secureState.encryptedData, SECRET_KEY);
                    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
                    
                    if (!decryptedString) {
                        console.error('Failed to decrypt user data');
                        return null;
                    }
                    
                    // Parse the decrypted data
                    const decryptedData = JSON.parse(decryptedString);
                    
                    // Verify integrity with hash
                    const computedHash = CryptoJS.HmacSHA256(decryptedString, SECRET_KEY).toString();
                    
                    if (computedHash !== secureState.hash) {
                        console.warn('Auth data tampering detected!');
                        return null;
                    }
                    
                    return decryptedData;
                } catch (error) {
                    console.error('Error decrypting user data:', error);
                    return null;
                }
            }
            
            // If we don't have the expected structure, return as is
            // (this handles backward compatibility)
            return outboundState;
        }
        return outboundState;
    },
    // Configuration options
    { whitelist: ['user'] }
);

export default SecureAuthTransform; 