const Joi = require('joi')

const envVarsSchema = Joi.object()
    .keys({
        NODE_ENV: Joi.string()
            .valid('production', 'development', 'demo', 'test')
            .required(),
        REACT_APP_BASE_URL: Joi.string()
            .allow('')
            .default('')
            .description('backend server url'),
        REACT_APP_ENGINE_URL: Joi.string()
            .allow('')
            .default('')
            .description('data library url'),
        REACT_APP_STRIPE_PUBLIC_KEY: Joi.string()
            .required()
            .description('Stripe public key'),
        REACT_APP_USERS_PER_PAGE: Joi.number()
            .default(10)
            .description('number of users per page in users table'),
        REACT_APP_GOOGLE_OAUTH_CLIENT_ID: Joi.string().required(),
        REACT_APP_GOOGLE_MAPS_API_KEY: Joi.string().required(),
    })
    .unknown()

const { value: envVars, error } = envVarsSchema
    .prefs({ errors: { label: 'key' } })
    .validate(process.env)

if (error) {
    throw new Error(`Config validation error: ${error.message}`)
}

export const config = {
    api: {
        url: envVars.REACT_APP_BASE_URL,
        dataLibraryUrl: envVars.REACT_APP_ENGINE_URL,
    },
    stripe: {
        publicKey: envVars.REACT_APP_STRIPE_PUBLIC_KEY,
    },
    google: {
        clientId: envVars.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        mapsApiKey: envVars.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
    users: {
        resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
    },
    jwt: {
        accessLifetime: envVars.ACCESS_TOKEN_LIFETIME,
        refreshLifetime: envVars.REFRESH_TOKEN_LIFETIME,
    },
}
